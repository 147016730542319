<template>
  <div class="centered-wrapper container-wrapper">
    <Logo class="logo" />
    <FormWrapper @submit="submit">
      <h2 class="main-title">Введите код из sms</h2>
      <div class="inputwrappper u-mt--2 u-mb--3">
        <vue-number-input class="custom-input" v-model="num1" inline :max="9" :min="0" />
        <vue-number-input class="custom-input" v-model="num2" inline :max="9" :min="0" />
        <vue-number-input class="custom-input" v-model="num3" inline :max="9" :min="0" />
        <vue-number-input class="custom-input" v-model="num4" inline :max="9" :min="0" />
      </div>
      <Button width="100%" class="u-mb--1" @click.prevent="submit"> Отправить </Button>
    </FormWrapper>
  </div>
</template>

<script>
// import api from "@/core/api";
import { auth } from "@/core/services/auth";
import { getUrl, isUrlExists, removeUrl } from "@/core/utils/returnUrlController";
import api from "@/core/api";

import FormWrapper from "@/core/components/FormWrapper/FormWrapper.vue";
import Button from "@/core/components/Button/Button.vue";

import Logo from "@/assets/icons/logo.svg";

export default {
  components: { Button, FormWrapper, Logo },
  data() {
    return {
      num1: null,
      num2: null,
      num3: null,
      num4: null,
    };
  },
  methods: {
    redirectToBack: function (id) {
      const returnUrl = getUrl(id);
      removeUrl();
      window.open(returnUrl, "_self");
    },

    getToken: function (id) {
      api.getToken(id).then((resp) => {
        auth.setToken(resp.data.access_token);
        this.$router.push({ name: "profile" });
      });
    },
    submit() {
      const code = `${this.num1}${this.num2}${this.num3}${this.num4}`;
      console.log(code);
      try {
        if (isUrlExists()) {
          this.redirectToBack(code);
        } else {
          this.getToken(code);
        }
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped src="./Sms.scss"></style>
